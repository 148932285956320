/* 布局 */
//前台
import Layout from "@views/layout";

const questionsubjecttemplateRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
        {
            path: "questionsubjecttemplate",
            name: "QUESTIONSUBJECTTEMPLATE",
            meta: {
                title: "",
                sort: 1,
            },
            component: () => import("@views/business/questionsubjecttemplate/index.vue"),
            redirect: {
                name: "QUESTIONSUBJECTTEMPLATE_LIST",
            },
            children: [
                {
                    path: "list",
                    name: "QUESTIONSUBJECTTEMPLATE_LIST",
                    meta: {
                        title: "",
                        sort: 2,
                        keepAlive: true
                    },
                    component: () => import("@views/business/questionsubjecttemplate/list.vue"),
                },
                {
                    path: "add",
                    name: "QUESTIONSUBJECTTEMPLATE_ADD",
                    meta: {
                        title: "新增调查问卷模板题目表",
                        parentPath: '/business/questionsubjecttemplate/list',
                        operate: true,
                    },
                    component: () => import("@views/business/questionsubjecttemplate/form.vue"),
                },
                {
                    path: "edit",
                    name: "QUESTIONSUBJECTTEMPLATE_EDIT",
                    meta: {
                        title: "编辑调查问卷模板题目表",
                        parentPath: '/business/questionsubjecttemplate/list',
                        operate: true,
                    },
                    component: () => import("@views/business/questionsubjecttemplate/form.vue"),
                },
            ],
        },


    ],
};

export default questionsubjecttemplateRouter;
