import Vue from "vue";
import "normalize.css/normalize.css"; // 替代CSS重置
import "@utils/element-ues"; // 按需引入ElementUI
import "@assets/styles/public.scss"; // 全局引用样式

import "@assets/styles/master.css"; // 全局引用样式

import App from "./App.vue";
import router from "./router";
import store from "./store";

import "./permission"; // 权限控制

import _ from "lodash"; // JavaScript 实用工具库

import * as filters from "@/filters"; // 全局过滤器

import * as directives from "./directives"; // 全局过指令

import * as echarts from "echarts"; // 引入echarts

//ueditor
import config from "../lib/ueditor/ueditor.config.js";
import "../lib/ueditor/ueditor.all.min.js";
import "../lib/ueditor/lang/zh-cn/zh-cn.js";

//print
import print from "print-js";

import WujieVue from "wujie-vue2";
Vue.use(WujieVue);


Vue.prototype.$bus = new Vue();

// 处理自定义组件全局注册
const files = require.context("./components/try", true, /\.vue$/);
files.keys().forEach((routerPath) => {
  const componentName = routerPath.replace(/^\.\/(.*)\/index\.\w+$/, "$1");
  if (componentName.indexOf("/") === -1) {
    const value = files(routerPath);
    Vue.component(
      "try" + componentName.replace(/([A-Z])/g, "-$1").toLowerCase(),
      value.default
    );
    // console.log('try' + componentName.replace(/([A-Z])/g, '-$1').toLowerCase());
  }
}, {});
import VueClipboard from "vue-clipboard2";
Vue.use(VueClipboard);

// 图片裁剪
import VueCropper from "vue-cropper";
Vue.use(VueCropper);

//screenfull
import screenfull from "screenfull";

Vue.prototype.config = config;

// 全屏挂载
Vue.prototype.$screenfull = screenfull;
/* lodash工具库全局挂载 */
Vue.prototype.$_ = _;
/* echarts全局挂载 */
Vue.prototype.$echarts = echarts;

Vue.prototype.resetSetItem = function (key, newVal) {
  //这个key就是我们要监听的那个key
  if (key === "tws_id" || key === "regionId") {
    // 创建一个StorageEvent事件
    var newStorageEvent = document.createEvent("StorageEvent");
    const storage = {
      setItem: function (k, val) {
        //这里可以灵活更改local和session
        sessionStorage.setItem(k, val);
        // 初始化创建的事件
        newStorageEvent.initStorageEvent(
          "setItem",
          false,
          false,
          k,
          null,
          val,
          null,
          null
        );
        // 派发对象
        window.dispatchEvent(newStorageEvent);
      },
    };
    return storage.setItem(key, newVal);
  }
};
// 注册全局过滤器
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});

// 注册全局过指令
Object.keys(directives).forEach((key) => {
  Vue.use(directives[key]);
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

/** 权限指令**/
Vue.directive("prikey", {
  inserted(el, binding) {
    //获取缓存的按钮数组
    const prilist = sessionStorage.getItem("prilist");

    const str = binding.value;
    var priarr = JSON.parse(prilist);

    var priItem = priarr.find((item) => item.anode_code === str);
    // 不存在则隐藏
    if (priarr === null || priarr === undefined || priItem === undefined) {
      const alllist = sessionStorage.getItem("alllist");
      var allpriarr = JSON.parse(alllist);

      var checkItem = allpriarr.find((item) => item.anode_code === str);

      if (checkItem.anode_disabled == 1) {
        el.parentNode.removeChild(el);
      } else {
        el.disabled = true;
        // el.addClass(".dis-gray")
        el.classList.add("dis-gray");
      }
    }
  },
});

/** 监听元素大小变化 */
Vue.directive("resize", {
  bind(el, binding) {
    let width = "";
    let height = "";

    const initResizeObserver = () => {
      const resizeObserver = new ResizeObserver((entries) => {
        for (let entry of entries) {
          const { width: newWidth, height: newHeight } = entry.contentRect;
          if (width !== newWidth || height !== newHeight) {
            binding.value({ width: newWidth, height: newHeight });
            width = newWidth;
            height = newHeight;
          }
        }
      });

      resizeObserver.observe(el);

      el.__resizeObserver__ = resizeObserver;
    };

    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        initResizeObserver();
        observer.disconnect();
      }
    });

    observer.observe(el);
  },
  unbind(el) {
    if (el.__resizeObserver__) {
      el.__resizeObserver__.disconnect();
      delete el.__resizeObserver__;
    }
  },
});
Vue.directive("resize2", {
  bind(el, binding) {
    let width = "";
    let height = "";
    function isResize() {
      const style = document.defaultView.getComputedStyle(el);
      if (width !== style.width || height !== style.height) {
        binding.value({ width: style.width, height: style.height }); // 关键(这传入的是函数,所以执行此函数)
      }
      width = style.width;
      height = style.height;
    }
    el.__vueSetInterval__ = setInterval(isResize, 300);
  },
  unbind(el) {
    clearInterval(el.__vueSetInterval__);
  },
});
