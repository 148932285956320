/* 布局 */
//前台
import Layout from "@views/layout";

const activitytemplateRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
        {
            path: "activitytemplate",
            name: "ACTIVITYTEMPLATE",
            meta: {
                title: "模板管理",
                sort: 1,
            },
            component: () => import("@views/business/activitytemplate/index.vue"),
            redirect: {
                name: "ACTIVITYTEMPLATE_LIST",
            },
            children: [
                {
                    path: "list",
                    name: "ACTIVITYTEMPLATE_LIST",
                    meta: {
                        title: "模板管理",
                        sort: 2,
                        keepAlive: true
                    },
                    component: () => import("@views/business/activitytemplate/list.vue"),
                },
                {
                    path: "questiontemplateadd",
                    name: "QUESTIONNAIRETEMPLATE_ADD",
                    meta: {
                        title: "调查问卷模板表",
                        parentPath: '/business/activitytemplate/list',
                        operate: true,
                    },
                    component: () => import("@views/business/questionnairetemplate/form.vue"),
                },
                {
                    path: "questiontemplateedit",
                    name: "QUESTIONNAIRETEMPLATE_EDIT",
                    meta: {
                        title: "调查问卷模板表",
                        parentPath: '/business/activitytemplate/list',
                        operate: true,
                    },
                    component: () => import("@views/business/questionnairetemplate/form.vue"),
                },
                {
                    path: "questiontemplateinfo",
                    name: "QUESTIONNAIRETEMPLATE_INFO",
                    meta: {
                        title: "调查问卷模板表",
                        parentPath: '/business/activitytemplate/list',
                        operate: true,
                    },
                    component: () => import("@views/business/questionnairetemplate/components/detail.vue"),
                },


                {
                    path: "activitytemplatelist",
                    name: "TEMPLATEMANAGEACTIVITY_LIST",
                    meta: {
                        // title: "话题模板",
                        // parentPath: '/business/activitytemplate/list',
                        // operate: true,
                        title: "话题模板",
                        sort: 2,
                        keepAlive: true
                    },
                    component: () => import("@views/business/templatemanageactivity/list.vue"),
                },
                {
                    path: "activitytemplateadd",
                    name: "TEMPLATEMANAGEACTIVITY_ADD",
                    meta: {
                        title: "新增模板管理-话题模板（其实是活动名称）",
            
                        parentPath: '/business/activitytemplate/list',
                        operate: true,
                    },
                    component: () => import("@views/business/templatemanageactivity/form.vue"),
                },
                {
                    path: "activitytemplateedit",
                    name: "TEMPLATEMANAGEACTIVITY_EDIT",
                    meta: {
                        title: "编辑模板管理-话题模板（其实是活动名称）",
     
                        parentPath: '/business/activitytemplate/list',
                        operate: true,
                    },
                    component: () => import("@views/business/templatemanageactivity/form.vue"),
                },

            ],
        },


    ],
};

export default activitytemplateRouter;
